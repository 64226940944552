import useBaseRequest from "../../api/BaseRequest";
import DocumentGateway from "../../api/gateways/DocumentGateway";
import { useEffect, useState } from "react";
import { useUser } from "../../store/auth/AuthSelectors";
import DigitalSignatureGateway from "../../api/gateways/DigitalSignatureGateways";
import { useToast } from "../../components/toast";

export const useDigitalSignature = () => {
  const user = useUser();
  const { success } = useToast();
  const payload =
    "?filters=" +
    JSON.stringify({
      name: "digitalSignRequest => status",
      type: "stringArray",
      operator: "in",
      value: JSON.stringify(["pending", "confirmed"]),
    }) +
    `&filters=${JSON.stringify({
      name: "dealerCode",
      operator: "eq",
      value: user?.dealerCode,
      type: "string",
    })}` +
    "&includes=" +
    JSON.stringify({
      assosiation: "digitalSignRequest",
      required: true,
    }) +
    "&includes=" +
    JSON.stringify({
      assosiation: "files",
      required: true,
      hasMany: true,
    }) +
    "&limit=20";
  useEffect(() => {
    if (user) {
      execute(payload);
    }
  }, [user]);
  const { data, execute, loading } = useBaseRequest(
    DocumentGateway.getDocuments
  );

  const { execute: deleteDigitalSignatureRequest, loading: deletingSignature } =
    useBaseRequest(DigitalSignatureGateway.deleteDigitalSignatureRequest, {
      onCompleted: (data) => {
        success("Digital Signature Request deleted successfully.");
        closeDeleteModal();
        setSelectedId(undefined);
        execute(payload);
      },
    });
  const [selectedId, setSelectedId] = useState<number>();

  const closeDeleteModal = () => {
    setSelectedId(undefined);
  };
  const onStartDelete = (id: number) => {
    setSelectedId(id);
  };
  const onDelete = () => {
    selectedId && deleteDigitalSignatureRequest(selectedId);
  };
  const onResend = (id: number) => {};
  return {
    documents: data?.documents || [],
    deletingSignature,
    closeDeleteModal,
    showDeleteModal: selectedId !== undefined,
    loading,
    onDelete,
    onStartDelete,
    onResend,
  };
};
