import { ILog, LogsResponse } from "../../components/logs/Logs.types";
import {
  AddCommentToTaskDTO,
  CreateTaskDTO,
  UpdateTaskDTO,
  GetTaskCommentDTO,
  TaskReassignDTO,
  TaskMoveToAnotherBasketDTO,
  ITask,
  GetTaskStatsResponse,
  IGetTasksApi,
  TaskChangeStatusDTO,
  TaskBulkMoveToAnotherBasketDTO,
} from "../../entities/ITask";
import { TaskStatusWithCommentChangeDTO } from "../../screens/task/Task.types";
import { TaskBulkReassignDTO } from "../../entities/ITask";
import BaseInstance from "../BaseInstance";

const TaskGateway = {
  createTask: async (payload: CreateTaskDTO): Promise<ITask> => {
    return BaseInstance.post("/tasks", {
      ...payload,
      description: payload.description?.trim() || null,
      customerName: payload.customerName?.trim() || null,
    });
  },
  updateTask: async (payload: UpdateTaskDTO): Promise<ITask> => {
    return BaseInstance.patch(`/tasks`, {
      taskIds: [payload.id],
      data: {
        description: payload.description?.trim() || null,
        customerName: payload.customerName?.trim() || null,
        taskTypeCode: payload.taskTypeCode ? payload.taskTypeCode : undefined,
      },
    });
  },
  getTaskById: async (id: number): Promise<ITask> => {
    return BaseInstance.get(`/tasks/${id}`);
  },
  cancelTaskById: async (
    payload: TaskStatusWithCommentChangeDTO
  ): Promise<ITask> => {
    return BaseInstance.put(`/tasks/${payload.id}/status`, {
      comment: payload.comment,
      status: "canceled",
    });
  },
  onHoldTaskById: async (
    payload: TaskStatusWithCommentChangeDTO
  ): Promise<ITask> => {
    return BaseInstance.put(`/tasks/${payload.id}/status`, {
      comment: payload.comment || null,
      status: "onhold",
    });
  },
  resolveTaskById: async (
    payload: TaskStatusWithCommentChangeDTO
  ): Promise<ITask> => {
    return BaseInstance.put(`/tasks/${payload.id}/status`, {
      comment: payload.comment || null,
      status: "resolved",
    });
  },
  reassignTaskById: async (payload: TaskReassignDTO): Promise<ITask> => {
    return BaseInstance.put(`/tasks/${payload.id}/status`, {
      userId: payload.userId,
      status: "assigned",
    });
  },
  unassignTaskById: async (id: number): Promise<ITask> => {
    return BaseInstance.post(`/tasks/${id}/unassign`);
  },
  changeStatus: async (payload: TaskChangeStatusDTO): Promise<ITask> => {
    return BaseInstance.put(`/tasks/${payload.id}/status`, {
      status: payload.status,
    });
  },
  addCommentToTask: async (payload: AddCommentToTaskDTO): Promise<ITask> => {
    return BaseInstance.post(`/task-comments`, payload);
  },
  getCommentsByTaskId: async (id: number): Promise<GetTaskCommentDTO> => {
    return BaseInstance.get(`/tasks/${id}/comments?limit=1000`);
  },
  moveToAnotherBasket: async (
    payload: TaskMoveToAnotherBasketDTO
  ): Promise<ITask> => {
    return BaseInstance.put(`/tasks/${payload.id}/change-basket`, {
      basketId: payload.basketId,
    });
  },
  getTaskStats: async (query: string): Promise<GetTaskStatsResponse> => {
    return BaseInstance.get(`/tasks/stats${query}`);
  },
  getTasks: async (query: string): Promise<IGetTasksApi> => {
    return BaseInstance.get(`/tasks${query}`);
  },
  getTaskLogsById: async (id: string): Promise<LogsResponse> => {
    return BaseInstance.get(
      `/tasks/logs?limit=1000&filters=${JSON.stringify({
        name: "taskId",
        operator: "eq",
        value: JSON.stringify(+id),
      })}`
    );
  },
  reassignBulkTasks: async (payload: TaskBulkReassignDTO): Promise<ITask> => {
    return BaseInstance.patch(`/tasks`, payload);
  },
  moveToAnotherBasketBulkTasks: async (
    payload: TaskBulkMoveToAnotherBasketDTO
  ): Promise<ITask> => {
    return BaseInstance.patch(`/tasks`, payload);
  },
 
  sendSMSComplaint: async (payload: { taskId: number }): Promise<SMSComplaintResult> => {
    return BaseInstance.post<
      any,
      SMSComplaintResult,
      any
    >(`/tasks/resend?taskId=${payload.taskId}&force=true`);
  },
};
type SMSComplaintResult = {
  isSent: boolean; 
  note: string;
  errorMessage?: string;
};
export default TaskGateway;
