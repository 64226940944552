import { Button } from "../../components/button";
import DocumentForm from "../../components/document-form/DocumentForm";
import { FormPreview } from "../../components/form-preview";
import { GenericForm } from "../../components/generic-form";
import { RoleBasedAccess } from "../../components/guard";
import Header from "../../components/header/Header";
import { UserRoles } from "../../entities/IUser";
import { Icon } from "../../modules/icon";
import { Loader } from "../../modules/loader";
import useTaskDetails from "./useTaskDetails";

const TaskDetails = () => {
  const {
    task,
    loading,
    updateTask,
    updateTaskForm,
    updating,
    updateInProgress,
    onCancelUpdate,
    onUpdateStart,
    sending,
    sendTask,
    user,
    onDocUpdate,
    taskFields,
  } = useTaskDetails();
  if (loading) {
    return (
      <div className="w-100 flex-row justify-content-center">
        <Loader size="l" color="black" />
      </div>
    );
  }
  if (!task) {
    return <div>Tasks not found</div>;
  }
  return (
    <>
      <div className="mt-4 flex-column gap-2">
        <Header
          actions={
            <RoleBasedAccess
              allowedRoles={[
                UserRoles.ADMIN,
                UserRoles.SUPERVISOR,
                UserRoles.BO_EXPERT,
              ]}
            >
              {user?.role === UserRoles.BO_EXPERT &&
              task.assignee?.id !== user?.id ? null : (
                <Button
                  className={`btn ${
                    updateInProgress ? "btn-danger" : "btn-secondary"
                  } `}
                  onClick={updateInProgress ? onCancelUpdate : onUpdateStart}
                >
                  <Icon
                    type={`fa-solid fa-${
                      updateInProgress ? "xmark" : "file-pen"
                    }`}
                  />
                  &nbsp;{" "}
                  {updateInProgress ? "Cancel Editing" : "Edit Task Details"}
                </Button>
              )},
              { !task.complaint ? null : (
                <Button
                  className={`btn ${
                    sending ? "btn-danger" : "btn-secondary"
                  } `}
                  onClick={() => {
                    if(!sending)
                      sendTask({taskId : task.id});
                  }}
                  disabled={sending}
                >
                  <Icon
                    type={`fa-solid fa-comment-sms }`}
                  />
                  &nbsp;{" "}
                  {sending ? "Sending" : `${task?.complaint?.notifiedAt ? "Res":"S"}end SMS`}
                </Button>
              ) }
            </RoleBasedAccess>
          }
          title="Task Details"
        />
        {!updateInProgress ? (
          <FormPreview fields={taskFields} />
        ) : (
          <GenericForm
            form={updateTaskForm}
            onSubmit={updateTask}
            submitControl={(submit) => (
              <div className="flex-row">
                <Button
                  isLoading={updating}
                  loader={{ size: "s" }}
                  className="btn btn-primary mt-3 w-100"
                  type="submit"
                >
                  Submit
                </Button>
              </div>
            )}
          />
        )}
      </div>
      {task.document?.id && (
        <DocumentForm
          id={task.document.id.toString()}
          task={task}
          onDocUpdate={onDocUpdate}
        />
      )}
    </>
  );
};

export default TaskDetails;
