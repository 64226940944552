import React, { useEffect } from "react";
import useBaseRequest from "../../api/BaseRequest";
import DocumentGateway from "../../api/gateways/DocumentGateway";
import { TableRow } from "../../components/table";
import moment from "moment";
import { DocumentStatus, archivedStatues } from "../../entities/IDocument";
import { Icon } from "../../modules/icon";
import { useUser } from "../../store/auth/AuthSelectors";
import { documentTableHeaders } from "./Documents.types";
import { useNavigate } from "react-router";
import { screens } from "../Navigation.types";
import { useDocumentService } from "../../services/useDocumentService";
import { Link, useLocation } from "react-router-dom";
import { Badge } from "../../components/badge";

export function useDocuments() {
  // states
  const user = useUser();
  const navigate = useNavigate();
  const { getDocFileUrl } = useDocumentService();
  const location = useLocation();

  useEffect(() => {
    execute(
      (location.search || "?") +
        `&filters=${JSON.stringify({
          name: "status",
          operator: "in",
          value: JSON.stringify(archivedStatues),
          type: "stringArray",
        })}` +
        `&filters=${JSON.stringify({
          name: "dealerCode",
          operator: "eq",
          value: user?.dealerCode,
          type: "string",
        })}` +
        "&includes=" +
        JSON.stringify({
          assosiation: "digitalSignRequest",
          required: false,
        })
    );
  }, [location.search]);

  const [docTableData, setDocTableData] = React.useState<TableRow[]>([]);
  const [selectedDocuments, setSelectedDocuments] = React.useState<number[]>(
    []
  );
  const [showBoxModal, setShowBoxModal] = React.useState(false);

  // requests
  const { data, execute, loading } = useBaseRequest(
    DocumentGateway.getDocuments,
    {
      onCompleted: (data) => {
        const { documents } = data;
        const docTable: TableRow[] = [];
        documents.forEach((doc) => {
          docTable.push([
            doc.id,
            doc.metaData?.jmbg || doc.metaData?.mb || "",
            doc.metaData?.msisdn || "",
            doc.metaData?.signDate
              ? moment(doc.metaData?.signDate).format("DD.MM.YYYY")
              : "",
            doc.metaData?.documentCategory +
              " - " +
              doc.metaData?.documentType || "",
            doc.metaData?.archiveType ? (
              <Badge
                variant="outlined"
                type={
                  doc.metaData?.archiveType === "Digital"
                    ? "primary"
                    : "secondary"
                }
              >
                {doc.metaData?.archiveType}
              </Badge>
            ) : (
              ""
            ),

            doc?.metaData?.boxId || "",
            moment(doc?.createdAt).format("DD.MM.YYYY"),
            doc.createdBy?.name || "",
            doc.metaData?.documentCategory === "Residential"
              ? ""
              : doc.metaData?.isElectronicallySigned
              ? "Yes"
              : "No",
            doc.metaData?.documentCategory === "Business"
              ? ""
              : doc.digitalSignRequest?.status === "signed"
              ? "Yes"
              : "No",
            <div>
              <Link
                target="_blank"
                rel="noreferrer"
                className="mr-3"
                to={
                  doc.files && doc.files.length
                    ? getDocFileUrl(doc.files[doc.files.length - 1].url)
                    : "/"
                }
              >
                <Icon
                  type="fa-solid fa-file"
                  color="black"
                  size={16}
                  title={"See file"}
                />
              </Link>
              <Link to={`/documents/${doc.id}`}>
                <Icon
                  type="fa-solid fa-circle-info"
                  color="black"
                  size={16}
                  title={"Show document info"}
                />
              </Link>
              {doc.task ? (
                <Link to={`/task/${doc.task.id}`} className="ml-3">
                  <Icon
                    type="fa-solid fa-tasks"
                    color="black"
                    size={16}
                    title="See task"
                  />
                </Link>
              ) : (
                ""
              )}
            </div>,
          ]);
        });
        setDocTableData(docTable);
      },
    }
  );

  const { execute: updateDocs, loading: updating } = useBaseRequest(
    DocumentGateway.updateDocuments,
    {
      onCompleted: (data) => {
        setShowBoxModal(false);
        setSelectedDocuments([]);
        execute(
          (location.search || "?") +
            `&filters=${JSON.stringify({
              name: "status",
              operator: "ne",
              value: DocumentStatus.DRAFT,
              type: "string",
            })}` +
            `&filters=${JSON.stringify({
              name: "dealerCode",
              operator: "eq",
              value: user?.dealerCode,
              type: "string",
            })}`
        );
      },
    }
  );

  // handlers

  const onRowPress = (id: number) => {
    navigate(screens.documents + `/${id}`);
  };

  const onAddBoxId = (boxId: string) => {
    updateDocs({ documentIds: selectedDocuments, metaData: { boxId } });
  };

  return {
    docTableData,
    count: data?.count || 0,
    getDocuments: execute,
    loading,
    user,
    selectedDocuments,
    setSelectedDocuments,
    showBoxModal,
    openBoxModal: () => setShowBoxModal(true),
    closeBoxModal: () => setShowBoxModal(false),
    onAddBoxId,
    updating,
    documentTableHeaders,
    onRowPress,
  };
}
